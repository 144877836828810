<template>
  <div class="mt-6">
    <div class="columns mx-6 my-6 pt-6 is-desktop">
      <div class="column"></div>
      <div class="column mx">
        <article class="">
          <GmapMap
            ref="FecMap"
            :zoom="16"
            map-type-id="terrain"
            style="width: 500px; height: 600px"
            class="px-6"
          >
            <GmapMarker
              :position="
                google &&
                  new google.maps.LatLng(28.647400447820214, -106.0685746609058)
              "
              :clickable="true"
              :draggable="False"
              @click="zoom = 20"
            />
          </GmapMap>
        </article>
      </div>
      <div class="column mx-6 is-fullhd">
        <h1 class="title has-text-left has-text-primary-dark">
          {{ $t("contacthead") }}
        </h1>
        <p class="has-text-left">{{ $t("contacttext") }}</p>
        <form class="mt-6 has-text-left" id="contactForm">
          <b-field label="Name">
            <b-input
              placeholder="John Doe"
              v-model="name"
              name="name"
              required
            ></b-input>
          </b-field>

          <b-field label="Email">
            <b-input
              type="email"
              v-model="email"
              placeholder="john@email.com"
              maxlength="30"
              name="email"
              required
            >
            </b-input>
          </b-field>

          <b-field label="Message">
            <b-input
              maxlength="200"
              type="textarea"
              placeholder="..."
              v-model="message"
              name="message"
              required
            ></b-input>
          </b-field>

          <vue-recaptcha
            ref="recaptcha"
            sitekey="6LceSyIaAAAAACqS--AvoT5cqcTOHBQa0CxofKVJ"
            @verify="onVerify"
            required
          >
          </vue-recaptcha>

          <b-button
            ref="sendButton"
            type="submit"
            class="is-primary my-6"
            v-on:click="sendEmail(e)"
            :disabled="recaptcha == false"
          >
            {{ $t("send") }}
          </b-button>
        </form>
      </div>
      <div class="column"></div>
    </div>
    <h1 class=" my-6 title has-text-primary has-text-center">
      {{ $t("support") }}
    </h1>
    <div class="tile is-ancestor mx-5">
      <div class="tile"></div>
      <div class="tile notification is-white-ter mx-5 is-3">
        <article class="has-text-left">
          <img
            :src="require('@/assets/Logos e ilustraciones/Icons-03.png')"
            width="100"
            alt=""
          />
          <h1 class="subtitle px-5 mx-2 has-text-primary-dark">
            {{ $t("visitus") }}
          </h1>
          <div class="px-5 mx-2">
            <span
              >FEC de México S.A. de C.V.<br />
              Manuel Doblado 2011-B <br />
              Chihuahua, Chih. México <br />
              31205
            </span>
          </div>
        </article>
      </div>
      <div class="tile notification is-white-ter mx-5 is-3">
        <article class="has-text-left">
          <img
            :src="require('@/assets/Logos e ilustraciones/Icons-06.png')"
            width="100"
            alt=""
          />
          <h1 class="subtitle px-5 mx-2 has-text-primary-dark">
            {{ $t("callus") }}
          </h1>
          <div class="px-5 mx-2">
            <span
              >+52 (614) 388-7646 <br />
              +52 (614) 421-8873
            </span>
          </div>
        </article>
      </div>
      <div class="tile notification is-white-ter mx-5 is-3">
        <article class="has-text-left">
          <img
            :src="require('@/assets/Logos e ilustraciones/Icons-05.png')"
            width="100"
            alt=""
          />
          <h1 class="subtitle px-5 mx-2 has-text-primary-dark">
            {{ $t("emailus") }}
          </h1>
          <div class="px-5 mx-2">
            <span>facturas@fecmx.co </span>
          </div>
        </article>
      </div>
      <div class="tile"></div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import emailjs from "emailjs-com";
import { gmapApi } from "vue2-google-maps";
export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      recaptcha: false
    };
  },
  computed: {
    google: gmapApi
  },
  methods: {
    sendEmail() {
      try {
        if (this.name && this.message && this.email) {
          emailjs.sendForm(
            "service_okjhywb",
            "template_je53rqq",
            "contactForm",
            "user_LSXAEZg7w7QlFDSsB5Lxp",
            {
              name: this.name,
              email: this.email,
              message: this.message
            }
          );
          alert("Message sent correctly.");
          this.$refs.recaptcha.reset();
        } else {
          alert("All fields required.");
          this.$refs.recaptcha.reset();
        }
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
    onVerify(response) {
      this.recaptcha = true;
      return response;
    }
  },
  mounted() {
    this.$refs.FecMap.$mapPromise.then(map => {
      map.panTo({ lat: 28.647400447820214, lng: -106.0685746609058 });
    });
  }
};
</script>

<style></style>
